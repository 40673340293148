// import React, { useState, useEffect } from 'react';
// import AdminSidebar from '../Layout/AdminSidebar';
// import Loading from '../Layout/Loading';
// import api from '../../api/axiosConfig';

// function AdminBlog() {
//   const [blogPosts, setBlogPosts] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlogPosts = async () => {
//       try {
//         const response = await api.get('/admin/blogs/');
//         setBlogPosts(response.data);
//       } catch (error) {
//         console.error('Error fetching blog posts:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchBlogPosts();
//   }, []);

//   if (loading) return <Loading />;

//   return (
//     <div>
//       <h1>Blog Management</h1>
//       <div>
//         {blogPosts.length > 0 ? (
//           blogPosts.map((post) => (
//             <div key={post.id}>
//               <h2>{post.title}</h2>
//               <p>{post.content}</p>
//             </div>
//           ))
//         ) : (
//           <p>No blog posts available.</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default AdminBlog; 


import React from 'react';
import AdminSidebar from '../Layout/AdminSidebar';
import BlogList from '../Blog/BlogList';
import { Link } from 'react-router-dom';

const AdminBlog = () => {
  return (
    <div className="flex">
      <AdminSidebar />
      <div className="flex-1 p-4">
        <h1 className="text-2xl font-bold mb-4">Blog Management</h1>
        <Link to="/admin/blog/new" className="bg-blue-500 text-white px-4 py-2 rounded">
          Add New Blog Post
        </Link>
        <BlogList />
      </div>
    </div>
  );
};

export default AdminBlog; 
