import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  const editor = useEditor({
    extensions: [StarterKit, Image],
    editable: false,
  });

  useEffect(() => {
    const fetchPost = async () => {
      try {
        console.log("Fetching post for slug:", slug);
        const response = await axios.get(`/api/blog/posts/${slug}/`);
        setPost(response.data);
        if (editor) {
          editor.commands.setContent(response.data.content);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setError('Blog post not found');
          navigate('/blog');
        } else {
          setError('Failed to load blog post');
        }
        console.error('Error fetching post:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, editor, navigate]);

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (error) return <div className="text-center py-8 text-red-500">{error}</div>;
  if (!post) return null;

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-6">{post.title}</h1>
      
      {post.featured_image && (
        <div className="mb-8">
          <img 
            src={post.featured_image} 
            alt={post.title}
            className="w-full rounded-lg shadow-lg"
          />
        </div>
      )}

      <div className="text-sm text-gray-500 mb-8">
        Published on {new Date(post.created_at).toLocaleDateString()}
      </div>

      <div className="prose max-w-none">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default BlogPost;
