import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RichTextEditor from '../Blog/RichTextEditor';
import { useParams, useNavigate } from 'react-router-dom';
import AdminSidebar from '../Layout/AdminSidebar';

const AdminBlogEditor = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { slug } = useParams(); // For editing, slug will be available
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      const fetchPost = async () => {
        try {
          const response = await axios.get(`/api/blog/posts/${slug}/`);
          setTitle(response.data.title);
          setContent(response.data.content);
        } catch (err) {
          setError('Failed to load blog post');
          console.error('Error fetching post:', err);
        }
      };

      fetchPost();
    }
  }, [slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (slug) {
        // Update existing post
        await axios.put(`/api/blog/posts/${slug}/`, { title, content });
      } else {
        // Create new post
        await axios.post('/api/blog/posts/', { title, content });
      }
      navigate('/admin/blog'); // Redirect to blog list after saving
    } catch (err) {
      setError('Failed to save blog post');
      console.error('Error saving post:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <div className="flex-1 p-4">
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-4xl font-bold mb-6">{slug ? 'Edit Blog Post' : 'Create Blog Post'}</h1>
          {error && <div className="text-red-500">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                required
              />
            </div>
            <RichTextEditor content={content} onChange={setContent} />
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminBlogEditor; 
